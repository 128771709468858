import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const AnnounceTypeScheme = z.enum(["general", "important"]);
export type AnnounceType = zod.infer<typeof AnnounceTypeScheme>;

export function announceTypeToString(type: AnnounceType) {
  switch (type) {
    case "general":
      return "一般";
    case "important":
      return "重要";
  }
}

export const AnnounceScheme = z.object({
  id: z.number().optional(),
  title: z.string(),
  content: z.string(),
  publishedAt: z.string().optional(),
  announceType: AnnounceTypeScheme,
});
export type Announce = zod.infer<typeof AnnounceScheme>;

export const AnnounceClientScheme = AnnounceScheme.extend({
  id: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
});
export type AnnounceClient = zod.infer<typeof AnnounceClientScheme>;
