<template>
  <div>
    <div class="mb-4">
      <div>
        <FormLabel label="開始時間" />
        <FormItemTime
          v-model="startAt"
          :errors="errors"
          form-id="startAt"
          label=""
          :minutes-increment="config.baseTimeUnit"
        />
      </div>
      <div>
        <FormLabel label="終了時間" />
        <FormItemTime
          v-model="finishAt"
          :errors="errors"
          form-id="finishat"
          label=""
          :minutes-increment="config.baseTimeUnit"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from "vue";
import { Label as FormLabel } from "/@/vue/components/Atom";
import { FormItemTime } from "/@/vue/components/Molecules";
import {
  defaultOrganizationConfig,
  OrganizationConfig,
  ZodFormattedErrors,
} from "/@/types";

const startAt = defineModel<string | undefined>("startAt");
const finishAt = defineModel<string | undefined>("finishAt");

defineProps<{
  errors: ZodFormattedErrors;
  disabled?: boolean;
}>();

const config =
  inject<OrganizationConfig>("organizationConfig") || defaultOrganizationConfig;
</script>

<style scoped></style>
