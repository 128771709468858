<template>
  <div>
    <div class="mb-4">
      <div v-if="shifts.length">
        <ShiftDetailsCard v-for="s in shifts" :shift="s" />
      </div>
    </div>

    <Card class="mb-4">
      <template #content>
        <AbsenceForm
          v-model:absenceType="form.absenceType"
          v-model:bodyTemperature="form.bodyTemperature"
          v-model:explanation="form.explanation"
          v-model:photo="form.photo"
          :errors="errors"
        />
      </template>
    </Card>

    <div class="flex justify-end w-full">
      <div class="me-2">
        <BasicButton
          variant="secondary"
          icon="pi pi-times"
          label="キャンセル"
          text
          @click="backto()"
        />
      </div>
      <div class="">
        <BasicButton
          button-type="submit"
          icon="pi pi-play"
          label="欠勤申請"
          @click="absenceApi"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch, inject } from "vue";
import {
  useRouterUtil,
  useUser,
  useAttendance,
  useZodScheme,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { AbsenceForm } from "/@/vue/components/Organisms/Attendances";
import { DetailsCard as ShiftDetailsCard } from "/@/vue/components/Organisms/Shifts";
import {
  AbsenceCheckScheme,
  AbsenceClient,
  AbsenceForm as AbsenceFormType,
  OrganizationClient,
} from "/@/types";
import Card from "primevue/card";
import { Ref } from "vue";

const { backto, goto } = useRouterUtil();

const { getHome } = useUser();

const { data: homeInfo } = getHome();

const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

// form

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<AbsenceFormType>(AbsenceCheckScheme);

watch(form, (f) => {
  if (f.explanation && f.explanation.length > 1000) {
    form.explanation = f.explanation.slice(0, 1000);
  }
});

function resetForm() {
  form.absenceType = "illness";
  form.bodyTemperature = 36.5;
  form.explanation = "";
  form.photo = undefined;
}

onMounted(() => {
  startValidation.value = true;
  resetForm();
});

// attendance

const { absence } = useAttendance();

async function absenceApi() {
  if (!selectedOrganization.value) {
    alert("組織を選択してください");
    return;
  }

  if (await absence(selectedOrganization.value.organizationMemberId, form)) {
    goto({ name: "UsersIndex" });
  } else {
    alert("欠勤の処理に失敗しました");
  }
}

// shift
const shifts = computed(() => {
  const organization = selectedOrganization.value;
  const homeInf = homeInfo.value;
  if (!organization || !homeInf) return [];

  return homeInf.shifts.filter(
    (s) => s.organizationMemberId === organization.organizationMemberId
  );
});
</script>

<style scoped></style>
