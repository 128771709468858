<template>
  <div>
    <div class="file-upload-component">
      <FileUpload
        :multiple="true"
        :file-limit="fileLimit"
        accept=".pdf,.docx,.doc,image/*"
        :max-file-size="2000000"
        :show-upload-button="false"
        :show-cancel-button="false"
        :invalid="invalid"
        invalid-file-size-message="ファイルのサイズが大きすぎます"
        :invalid-file-limit-message="`添付できるファイルは${fileLimit}件のみです`"
        invalid-file-type-message="ファイルの形式が正しくありません。添付可能なファイル形式はPDF,Word,画像のみです。"
        choose-label="ファイル選択"
        @select="selectFile"
        @remove="removeFile"
      >
        <template #empty>
          <p class="text-danger">
            ファイル1つの最大サイズは2MBです。添付可能なファイルの最大数はあと{{
              fileLimit
            }}件です。
          </p>
        </template>
        <template
          #content="{
            files,
            uploadedFiles,
            removeUploadedFileCallback,
            removeFileCallback,
          }"
        >
          <div class="flex flex-col gap-8 pt-4">
            <div v-if="files.length > 0">
              <div class="flex flex-wrap gap-4">
                <div
                  v-for="(file, fileIndex) of files"
                  :key="file.name + file.type + file.size"
                  class="p-8 rounded-border flex flex-col border border-surface items-center justify-center gap-4"
                >
                  <div v-if="isImage(file.name)">
                    <img
                      role="presentation"
                      :alt="file.name"
                      :src="file.objectURL"
                      width="100"
                      height="50"
                    />
                  </div>
                  <Inplace>
                    <template #display>
                      <span
                        class="font-semibold text-ellipsis max-w-60 whitespace-nowrap overflow-hidden"
                        >{{ extractFilename(fileNames[fileIndex]) }}</span
                      >
                    </template>
                    <template #content="{ closeCallback }">
                      <span class="inline-flex items-center gap-2">
                        <InputText
                          :model-value="extractFilename(fileNames[fileIndex])"
                          autofocus
                          @update:model-value="
                            updateFileName(
                              `${$event}.${extractExtension(
                                fileNames[fileIndex]
                              )}`,
                              fileIndex
                            )
                          "
                        />
                        <Button
                          icon="pi pi-times"
                          text
                          severity="danger"
                          @click="closeCallback"
                        ></Button>
                      </span>
                    </template>
                  </Inplace>
                  <Button
                    icon="pi pi-times"
                    @click="
                      onRemoveTemplatingFile(
                        file,
                        removeFileCallback,
                        fileIndex
                      )
                    "
                    outlined
                    rounded
                    severity="danger"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </FileUpload>
      <span
        v-if="newAttachedCount > fileLimit && invalid"
        class="text-danger mt-4"
      >
        添付可能な数を越えています
      </span>
      <span
        v-else-if="fileMin && fileMin > newAttachedCount"
        class="text-danger mt-4"
      >
        ファイルを添付して下さい。
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { extractExtension, extractFilename, isImage } from "/@/modules/file";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
import Inplace from "primevue/inplace";
import InputText from "primevue/inputtext";

const modelValue = defineModel<string[]>();

const props = withDefaults(
  defineProps<{
    fileLimit?: number;
    fileMin?: number;
    invalid?: boolean;
  }>(),
  {
    fileLimit: 1,
  }
);

const currentCount = computed(() => modelValue.value?.length || 0);

function embedFileNameToDataUrl(dataurl: string, filename: string) {
  return dataurl.replace(/;base64,/, `;name=${filename};base64,`);
}

function replaceFileNameInDateUrl(dataurl: string, filename: string) {
  return dataurl.replace(/;name=.*?;/, `;name=${filename};`);
}

const files = ref<File[]>([]);

function updateFileName(filename: string, idx: number) {
  const newFiles = [...files.value];
  // const myNewFile = new File([myFile], 'new_name.png', {type: myFile.type});
  newFiles[idx] = new File([newFiles[idx]], filename, {
    type: newFiles[idx].type,
  });

  files.value = newFiles;
}

watch(files, async (newFiles) => {
  const results: string[] = await Promise.all(
    newFiles.map(async (file: any) => {
      const reader = new FileReader();

      let blob = new Blob([file], { type: file.type });

      await reader.readAsDataURL(blob);

      return new Promise(
        (resolve) =>
          (reader.onload = () =>
            resolve(embedFileNameToDataUrl(reader.result as string, file.name)))
      );
    })
  );

  modelValue.value = results;
});

const fileNames = computed(() => {
  return files.value.map((file) => file.name);
});

async function selectFile(e: any) {
  files.value = e.files;
}

async function removeFile(e: any) {
  const files = e.files;

  const results: string[] = await Promise.all(
    files.map(async (file: any) => {
      const reader = new FileReader();

      let blob = new Blob([file], { type: file.type });

      await reader.readAsDataURL(blob);

      return new Promise(
        (resolve) => (reader.onload = () => resolve(reader.result))
      );
    })
  );
  modelValue.value = results;
}

const newAttachedCount = computed(() => {
  return modelValue.value?.length || 0;
});

function onRemoveTemplatingFile(
  file: any,
  removeFileCallback: (index: number) => void,
  index: number
) {
  removeFileCallback(index);
}
</script>

<style lang="scss">
.file-upload-component {
  .p-fileupload-file-thumbnail,
  .p-fileupload-file-badge {
    display: none;
  }
}

.file-upload-component:has(.p-fileupload-buttonbar .p-invalid) {
  border-color: red;
}
</style>
