import { MaybeRef } from "/@/types";
import useSWRV from "swrv";
import { axios, deepUndefineKeyToNull, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { AnnounceClient } from "/@/types";

export function useAnnounces() {
  function getAnnounce(
    id: MaybeRef<number>,
    organizationId: MaybeRef<number>,
    options: { system: boolean } = { system: false }
  ) {
    let baseUri;

    if (options.system) {
      baseUri = "/api/v1/system_announces";
    } else {
      baseUri = "/api/v1/announces";
    }

    return useSWRV<AnnounceClient>(
      () =>
        (id.value &&
          organizationId.value &&
          `${baseUri}/${id.value}` +
            `?organization_id=${organizationId.value}`) ||
        null,
      fetcher
    );
  }

  function getAnnounces(page: MaybeRef<number>, perPage: MaybeRef<number>) {
    function getKey(page: MaybeRef<number>, perPage: MaybeRef<number>) {
      let key = "/api/v1/announces?";

      if (page) {
        key += `page=${page.value}`;
      }

      if (perPage) {
        key += `&per_page=${perPage.value}`;
      }

      return key;
    }

    return useSWRV<{
      items: AnnounceClient[];
      totalPages: number;
      currentPage: number;
    }>(() => getKey(page, perPage), fetcher);
  }

  return {
    getAnnounce,
    getAnnounces,
  };
}
