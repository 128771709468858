<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <div class="flex flex-wrap items-center">
        <SelectObject
          v-if="organizations && organizations.length && false"
          v-model="selectedOrganizationId"
          option-label="name"
          option-value="id"
          :options="organizations"
          class="me-4 mb-4"
        />

        <SelectObject
          v-if="organizationMembers && organizationMembers.length"
          v-model="selectedOrganizationMemberId"
          :option-label="
            (member) => member.user?.lastName + member.user?.firstName
          "
          option-value="id"
          :options="organizationMembers"
          class="me-4 mb-4"
        />

        <SelectDate
          v-model:year="selectedDate.year"
          v-model:month="selectedDate.month"
          :existsDates="existsDates"
          class="mb-4"
        />
      </div>
    </div>

    <div class="bg-white dark:bg-gray-800 p-4 rounded-md">
      <div class="flex items-center">
        <div>
          <label>対象期間</label>
          <div v-if="selectedDate" class="flex items-center justify-center">
            <div class="flex items-center">
              <span>{{ selectedDate.year }}年</span>
              <span>{{ selectedDate.month }}月1日~</span>
              <span>{{ selectedDate.month }}月{{ lastDate }}日</span>
            </div>
          </div>
        </div>

        <div>
          <label>氏名</label>
          <div v-if="selectedOrganizationMember">
            <span>
              {{ selectedOrganizationMember.user.fullName }}
            </span>
          </div>
        </div>
      </div>

      <div v-if="attendanceInfo" class="flex flex-col">
        <table class="table-caption">
          <thead>
            <tr>
              <th>労働日数</th>
              <th>勤務日数</th>
              <th>休日勤務日数</th>
              <th>欠勤日数</th>
              <th>取得有給日数</th>
              <th>特別休暇日数</th>
              <th>有給残り日数</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ selectedDateLaborInformation.laborDays }}日</td>
              <td>{{ attendanceInfo.workingCount }}日</td>
              <td>{{ attendanceInfo.holidayWorkingCount }}日</td>
              <td>{{ attendanceInfo.absenceCount }}日</td>
              <td>{{ attendanceInfo.paidHolidayCount }}日</td>
              <td>{{ attendanceInfo.specialHolidayCount }}日</td>
              <td>{{ attendanceInfo.remainingPaidHolidayCount }}日</td>
            </tr>
          </tbody>
        </table>
        <table class="table-caption">
          <thead>
            <tr>
              <th>公休日数</th>
              <th>勤務時間</th>
              <th>休日勤務時間</th>
              <th>遅刻早退時間</th>
              <th>残業時間</th>
              <th>深夜残業時間</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ lastDate - selectedDateLaborInformation.laborDays }}日</td>
              <td>{{ attendanceInfo.workingTime }}時間</td>
              <td>{{ attendanceInfo.holidayWorkingTime }}時間</td>
              <td>{{ attendanceInfo.lateEarlyTime }}時間</td>
              <td>{{ attendanceInfo.overtime }}時間</td>
              <td>{{ attendanceInfo.midnightOvertime }}時間</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Card v-if="attendances && attendances.length">
      <template #content>
        <table class="table-fixed">
          <thead>
            <tr>
              <th>日付</th>
              <th>予定</th>
              <th>勤怠</th>
              <th>始業時間</th>
              <th>終業時間</th>
              <th>休憩</th>
              <th>実働時間</th>
              <th>休日勤務</th>
              <th>遅刻早退</th>
              <th>残業</th>
              <th>深夜残業</th>
              <th>備考</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in dates" :key="d.dateKey">
              <td>{{ basicFormatter(d.dateKey, "noYear") }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </template>
    </Card>

    <div
      v-if="attendances && attendances.length"
      class="bg-white dark:bg-gray-800 p-4 rounded-md"
    >
      <DataTable :value="dates" tableStyle="min-width: 50rem" scrollable>
        <Column field="dateKey" header="日にち" style="min-width: 135px">
          <template #body="slotProps">
            <Tag
              :value="basicFormatter(slotProps.data.dateKey, 'noYear')"
              :severity="dateSeverity(slotProps.data)"
            />
          </template>
        </Column>
        <Column field="startAt" header="時刻" style="min-width: 130px">
          <template #body="slotProps">
            <template v-if="eachDateAttendance[slotProps.data.dateKey]">
              <div
                v-for="(dk, idx) in eachDateAttendance[slotProps.data.dateKey]"
                :key="slotProps.data.dateKey + 'startAt'"
                class="flex"
                :class="{ 'mt-4': idx !== 0 }"
              >
                <Tag
                  v-if="dk.attendanceType === 'holiday'"
                  value="欠勤"
                  severity="danger"
                />
                <Tag
                  v-else-if="dk.attendanceType === 'paid_holiday'"
                  value="有休"
                  severity="danger"
                />
                <Tag
                  v-else
                  severity="success"
                  :value="`${basicFormatter(dk.startAt, 'onlyTime')}~${
                    dk.finishAt
                      ? basicFormatter(dk.finishAt, 'onlyTime')
                      : '勤務中'
                  }`"
                  class="me-2"
                />
              </div>
            </template>
          </template>
        </Column>
        <Column header="詳細" style="width: 100%; min-width: 200px">
          <template #body="slotProps">
            <template v-if="eachDateAttendance[slotProps.data.dateKey]">
              <div
                v-for="(dk, idx) in eachDateAttendance[slotProps.data.dateKey]"
                :key="dk.id"
                class="flex justify-between items-center"
                :class="{ 'mt-4': idx !== 0 }"
              >
                <div v-if="dk.attendanceType === 'holiday'">
                  <span class="me-2">
                    {{ absenceTypesLabel(dk.absenceType) }}
                  </span>
                  <span class="me-2"> {{ dk.bodyTemperature }}℃ </span>
                  <span>
                    {{ dk.explanation }}
                  </span>
                </div>
                <div v-else>
                  <span v-if="dk.evaluationType">
                    <Tag
                      :value="evaluationTypeLabel(dk.evaluationType)"
                      class="me-2"
                    />
                  </span>
                  <span v-if="dk.report">
                    {{ dk.report }}
                  </span>
                </div>
                <span v-if="dk.edited">※編集済</span>
              </div>
            </template>
          </template>
        </Column>
        <Column style="min-width: 100px">
          <template #body="{ data }">
            <div
              v-for="(at, idx) in eachDateAttendance[data.dateKey]"
              :key="at"
            >
              <BasicButton
                label="編集"
                icon="pi pi-pencil"
                class="me-2"
                text
                small
                :class="{ 'mt-4': idx !== 0 }"
                @click="openEditModal(at.id)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <Card v-else>
      <template #content> 選択した月のデータがありません </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, reactive, inject } from "vue";
import {
  basicFormatter,
  fromISO,
  luxonNow,
  existsDates,
} from "/@/modules/luxon";
import { createDateForCalendars } from "/@/modules/calendar";
import {
  useRouterUtil,
  useUser,
  useOrganizationMember,
  useAttendance,
  useZodScheme,
} from "/@/vue/composables";
import { BasicButton, SelectObject, SelectDate } from "/@/vue/components/Atom";
import {
  absenceTypesLabel,
  AttendanceCheckScheme,
  AttendanceClient,
  AttendanceForm as AttendanceFormType,
  DateMap,
  evaluationTypeLabel,
  OrganizationClient,
} from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import Card from "primevue/card";
import { Ref } from "vue";
import { zeroPadding } from "/@/modules/string";
import { a } from "vitest/dist/suite-IbNSsUWN";

const { goto } = useRouterUtil();

// user

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser({
  withTodayShifts: true,
});

// organization

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});

const selectedOrganizationId = inject("selectedOrganizationId");
const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganizationId,
  {
    with_labor_informations: true,
    with_attendance_record_info: true,
  }
);

const selectedOrganizationMemberId = ref<number>();
const selectedOrganizationMember = computed(() => {
  if (!organizationMembers.value) return;
  return organizationMembers.value.find(
    (m) => m.id === selectedOrganizationMemberId.value
  );
});

// date

const today = luxonNow();

const selectedDate = reactive<DateMap>({
  year: today.year,
  month: today.month,
});

const dates = computed(() => {
  return createDateForCalendars(selectedDate);
});

const lastDate = computed(() => {
  return dates.value[dates.value.length - 1].dayNumber;
});

function dateSeverity(date: { weekday: number }) {
  if (date.weekday === 5) {
    return "info";
  } else if (date.weekday === 6) {
    return "danger";
  } else {
    return "secondary";
  }
}

const selectedDateLaborInformation = computed(() => {
  if (!selectedOrganizationMember.value) return;
  return selectedOrganizationMember.value.laborInformations.find(
    (li) =>
      li.targetMonth ===
      `${selectedDate.year}-${zeroPadding(selectedDate.month, 2)}-01`
  );
});

// attendances

const { getAttendances } = useAttendance();
const { data: attendances } = getAttendances(
  selectedOrganizationMemberId,
  selectedDate
);

/*
  AttendanceInfo
  勤務日数
  休日勤務日数
  欠勤日数
  取得有給日数
  特別休暇日数
  有給残り日数
  勤務時間
  休日勤務時間
  遅刻早退時間
  残業時間
  深夜残業時間
*/

type AttendanceInfo = {
  workingCount: number;
  holidayWorkingCount: number;
  absenceCount: number;
  paidHolidayCount: number;
  specialHolidayCount: number;
  remainingPaidHolidayCount: number;
  workingTime: number;
  holidayWorkingTime: number;
  lateEarlyTime: number;
  overtime: number;
  midnightOvertime: number;
};

const attendanceInfo = computed(() => {
  if (!attendances.value) return;
  return {
    workingCount: attendances.value.filter(
      (a) => a.attendanceType === "working"
    ).length,
    holidayWorkingCount: attendances.value.filter(
      (a) => a.attendanceType === "holidayWorking"
    ).length,
    absenceCount: attendances.value.filter(
      (a) => a.attendanceType === "holiday"
    ).length,
    paidHolidayCount: attendances.value.filter(
      (a) => a.attendanceType === "paid_holiday"
    ).length,
    specialHolidayCount: attendances.value.filter(
      (a) => a.attendanceType === "special_holiday"
    ).length,
    remainingPaidHolidayCount: attendances.value.filter(
      (a) => a.attendanceType === "remaining_paid_holiday"
    ).length,
    workingTime: attendances.value
      .filter((a) => a.attendanceType === "working")
      .reduce((acc, a) => acc + a.workingTime, 0),
    holidayWorkingTime: attendances.value
      .filter((a) => a.attendanceType === "holidayWorking")
      .reduce((acc, a) => acc + a.workingTime, 0),
    lateEarlyTime: attendances.value
      .filter((a) => a.attendanceType === "working")
      .reduce((acc, a) => acc + a.lateEarlyTime, 0),
    overtime: attendances.value
      .filter((a) => a.attendanceType === "working")
      .reduce((acc, a) => acc + a.overtime, 0),
    midnightOvertime: attendances.value
      .filter((a) => a.attendanceType === "working")
      .reduce((acc, a) => acc + a.midnightOvertime, 0),
  };
});

const eachDateAttendance = computed(() => {
  if (attendances.value && attendances.value.length && dates.value.length) {
    let m = {};

    dates.value.forEach((d) => {
      m[d.dateKey] = attendances.value.filter(
        (a) => basicFormatter(a.startAt, "iso") === d.dateKey
      );
    });

    return m;
  } else {
    return {};
  }
});
</script>

<style scoped></style>
