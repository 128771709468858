import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const NotificationActionScheme = z.enum([
  "request",
  "announce",
  "system_announce",
]);
export type NotificationAction = zod.infer<typeof NotificationActionScheme>;

export function notificationActionToString(action: NotificationAction) {
  switch (action) {
    case "request":
      return "リクエスト";
    case "announce":
      return "アナウンス";
    case "system_announce":
      return "システムアナウンス";
  }
}

export const NotificationScheme = z.object({
  receipientId: z.number(),
  actorId: z.number().optional(),
  notifiable: z.any(),
  notifiableId: z.number(),
  notifiableType: z.string(),
  action: NotificationActionScheme,
  title: z.string(),
  content: z.string(),
});

export const NotificationClientScheme = NotificationScheme.extend({
  id: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  receipient: z.object({
    id: z.number(),
  }),
  actor: z.object({
    id: z.number(),
  }),
});
