import Absence from "./Absence.vue";
import Edit from "./Edit.vue";
import Index from "./Index.vue";
import New from "./New.vue";

export default [
  {
    path: "new",
    name: "UsersAttendancesNew",
    component: New,
    meta: {
      title: "業務開始",
    },
  },
  {
    path: "",
    name: "UsersAttendancesIndex",
    component: Index,
    meta: {
      title: "出勤記録",
    },
  },
  {
    path: ":id/edit",
    name: "UsersAttendancesEdit",
    component: Edit,
    meta: {
      title: "業務終了",
    },
  },
  {
    path: "absence",
    name: "UsersAttendancesAbsence",
    component: Absence,
    meta: {
      title: "欠勤",
    },
  },
];
