import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const LaborInformationScheme = z.object({
  id: z.number().optional(),
  organizationMemberId: z.number(),
  targetMonth: z.string(),
  laborDays: z.number(),
  publicHolidayDays: z.number(),
  holidayWorkDays: z.number(),
  specialHolidayDays: z.number(),
  paidHolidayDays: z.number(),
  leastPaidHolidayDays: z.number(),
  nextGetPaidHolidayDaysAt: z.string(),
  currentAttendanceDays: z.number(),
  currentShiftDays: z.number(),
});

export type LaborInformation = zod.infer<typeof LaborInformationScheme>;

export const LaborInformationClientScheme = LaborInformationScheme.extend({
  id: z.number(),
});

export type LaborInformationClient = zod.infer<
  typeof LaborInformationClientScheme
>;
