<template>
  <div>
    <div class="flex justify-end mb-4">
      <BasicButton disabled> ラベルの追加・変更 </BasicButton>
    </div>

    <DataTable :value="organizationMembers" scrollable>
      <Column
        :field="
          (om) =>
            `${om.user.lastName}${om.user.firstName}(${om.user.lastNameKana}${om.user.firstNameKana})`
        "
        header="名前"
        style="width: 200px"
      ></Column>
      <Column header="雇用形態" style="width: 200px">
        <template #body="{ data }">
          <Select
            :model-value="
              data.labels
                .filter((l) => !l._destroy && l.labelType === 'employment_type')
                .map((l) => l.labelName)[0]
            "
            :options="
              labelOptions.filter((l) => l.labelType === 'employment_type')
            "
            :option-label="(l) => l.labelName"
            :option-value="(l) => l.labelName"
            filter
            style="width: 100%"
            @update:model-value="updateMemberEmployeeType(data, $event)"
          >
            <!--template #chipicon="{ item }">
              <span
                class="p-chips-token-icon p-cursor-pointer"
                @click="removeMemberLabels(data, item)"
              >
                <i class="pi pi-times-circle"></i>
              </span>
            </ template -->
          </Select>
        </template>
      </Column>
      <Column header="役職" style="width: 200px">
        <template #body="{ data }">
          <MultiSelect
            :model-value="
              data.labels
                .filter((l) => !l._destroy && l.labelType === 'position')
                .map((l) => l.labelName)
            "
            :options="labelOptions.filter((l) => l.labelType === 'position')"
            :option-label="(l) => l.labelName"
            :option-value="(l) => l.labelName"
            filter
            class="w-full"
            @update:model-value="updateMemberLabels(data, $event)"
          >
            <!--template #chipicon="{ item }">
              <span
                class="p-chips-token-icon p-cursor-pointer"
                @click="removeMemberLabels(data, item)"
              >
                <i class="pi pi-times-circle"></i>
              </span>
            </ template -->
          </MultiSelect>
        </template>
      </Column>
      <Column></Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, inject, Ref } from "vue";
import {
  useRouterUtil,
  useOrganizationMember,
  useUser,
} from "/@/vue/composables";
import { SelectObject, BasicButton } from "/@/vue/components/Atom";
import { OrganizationClient, OrganizationMemberClient } from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Select from "primevue/select";

const { goto } = useRouterUtil();

// organization

const selectedOrganizationId = inject("selectedOrganizationId") as Ref<number>;
const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

const isManager = computed(() => selectedOrganization.value?.isManager);

const { getOrganizationMembers, updateOrganizationMember } =
  useOrganizationMember();
const { data: organizationMembers, mutate: getOrganizationMembersMutate } =
  getOrganizationMembers(selectedOrganizationId);

// label

const labelOptions = computed(() => {
  if (!selectedOrganization.value) return [];

  return selectedOrganization.value.labelOptions || [];
});

async function updateMemberEmployeeType(
  om: OrganizationMemberClient,
  event: string
) {
  console.log("updateMemberEmployeeType", om, event);

  om.labels = [
    ...om.labels.filter((l) => l.labelType !== "employment_type"),
    {
      labelType: "employment_type",
      labelName: event,
    },
  ];

  try {
    if (await updateOrganizationMember({ id: om.id, labels: om.labels })) {
      getOrganizationMembersMutate();
    } else {
      alert(
        "更新に失敗しました。添付されている画像が大きすぎます。画像が添付されていないにも関わらずこの表示が出る場合は、お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}

async function updateMemberLabels( // will change to updateMemberPositions
  om: OrganizationMemberClient,
  event: string[]
) {
  console.log("updateMemberLabels", event);

  const otherLabels = om.labels.filter((l) => {
    return l.labelType !== "position";
  });

  const positionLabels = om.labels.filter((l) => l.labelType === "position");

  const addLabels = event.filter((e) => {
    return !positionLabels.find((pl) => pl.labelName === e);
  });

  const destroyLabels = positionLabels.filter((pl) => {
    return !event.find((e) => e === pl.labelName);
  });

  om.labels = [
    ...otherLabels,
    ...addLabels.map((al) => {
      return {
        labelType: "position",
        labelName: al,
      };
    }),
    ...destroyLabels.map((dl) => {
      return {
        id: dl.id,
        labelType: "position",
        labelName: dl.labelName,
        _destroy: 1,
      };
    }),
  ];

  try {
    if (await updateOrganizationMember({ id: om.id, labels: om.labels })) {
      getOrganizationMembersMutate();
    } else {
      alert(
        "更新に失敗しました。添付されている画像が大きすぎます。画像が添付されていないにも関わらずこの表示が出る場合は、お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}

/*
function removeMemberLabels(
  om: OrganizationMemberClient,
  item: {
    labelType: OrganizationMemberLabelType;
    _destroy?: boolean;
  }
) {
  const target = om.labels.find((l) => l.labelType === item.labelType);
  target._destroy = 1;
}
*/
</script>

<style scoped></style>
