<template>
  <div>
    <template v-if="isManager">
      <div class="flex justify-end w-full my-2">
        <BasicButton
          label="ゴミ箱"
          icon="pi pi-trash"
          class="me-2"
          @click="goto({ name: 'UsersArticlesTrash' })"
        />
        <BasicButton
          label="新規作成"
          icon="pi pi-plus"
          @click="goto({ name: 'UsersArticlesNew' })"
        />
      </div>

      <ArticleTable
        :articles="articles?.items || []"
        :total-pages="articles?.totalPages || 0"
        v-model:per-page="selectedPerPage"
        v-model:current-page="currentPage"
        @select-article="selectArticle"
        @trash-article="handleTrashArticle"
      />
    </template>

    <div v-else>開発中</div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject, Ref } from "vue";
import {
  useRouterUtil,
  useArticle,
  useTableHeader,
  useUser,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Table as ArticleTable } from "/@/vue/components/Organisms/Articles";
import { OrganizationClient } from "/@/types";

const { goto } = useRouterUtil();

const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

const isManager = computed(() => selectedOrganization.value?.isManager);

const { currentPage, selectedPerPage } = useTableHeader();

const { getArticles, trashArticle } = useArticle();
const { data: articles, mutate: getArticlesMutate } = getArticles(
  currentPage,
  selectedPerPage
);

const articlesList = computed(() => articles.value?.items || []);

function selectArticle(id: number) {
  goto({ name: "UsersArticlesShow", params: { id: id } });
}

async function handleTrashArticle(id: number | number[]) {
  let m = "をゴミ箱に移動しますか？";

  if (Array.isArray(id)) {
    const targets = articlesList.value.filter((n) => id.includes(n.id));
    m = targets.at(0)?.title + " 他" + (targets.length - 1) + "件" + m;
  } else {
    const target = articlesList.value.find((n) => n.id === id);
    m = target?.title + m;
  }

  if (!window.confirm(m)) {
    return;
  }

  if (await trashArticle(id)) {
    getArticlesMutate();
  }
}
</script>

<style scoped></style>
