<template>
  <div>
    <div class="mb-4">
      <div v-if="shifts.length">
        <ShiftDetailsCard v-for="s in shifts" :shift="s" />
      </div>
      <div v-else>
        <Card>
          <template #content>
            <div>本日のシフトはありません</div>
          </template>
        </Card>
      </div>
    </div>

    <Card class="mb-4">
      <!-- attendance form -->
      <template #content>
        <GetLocation v-model:lat="form.lat" v-model:lng="form.lng" />
      </template>
    </Card>

    <div class="flex justify-end w-full">
      <div class="me-2">
        <BasicButton
          variant="secondary"
          icon="pi pi-times"
          label="キャンセル"
          text
          @click="backto()"
        />
      </div>
      <div class="">
        <BasicButton
          button-type="submit"
          icon="pi pi-upload"
          label="業務開始"
          :disabled="disabledStart"
          @click="start"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, inject, Ref } from "vue";
import { useRouterUtil, useUser, useAttendance } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { DetailsCard as ShiftDetailsCard } from "/@/vue/components/Organisms/Shifts";
import { GetLocation } from "/@/vue/components/Molecules";
import Card from "primevue/card";
import { OrganizationClient } from "/@/types";

const { backto, goto } = useRouterUtil();

const { getHome } = useUser();
const { data: homeInfo } = getHome();

const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

const shifts = computed(() => {
  const organization = selectedOrganization.value;
  const homeInf = homeInfo.value;
  if (!organization || !homeInf) return [];

  return homeInf.shifts.filter(
    (s) => s.organizationMemberId === organization.organizationMemberId
  );
});

// form

const form = reactive({
  lat: null,
  lng: null,
});

// attendance

const { start: startAttendance } = useAttendance();

const disabledStart = computed(() => {
  return !selectedOrganization.value || !form.lat || !form.lng;
});

async function start() {
  if (disabledStart.value) return;
  // @ts-ignore
  const organizationMemberId = selectedOrganization.value.organizationMemberId;

  if (
    await startAttendance({
      organizationMemberId,
      startLat: form.lat,
      startLng: form.lng,
    })
  ) {
    goto({ name: "UsersIndex" });
  } else {
    alert("業務開始に失敗しました");
  }
}
</script>

<style scoped></style>
