<template>
  <div>
    <Card v-if="announce">
      <template #title>
        <div class="flex items-center justify-between">
          <div class="text-xl font-bold">{{ announce.title }}</div>
          <Tag
            :value="announce.announceType === 'important' ? '重要' : '一般'"
            :severity="
              announce.announceType === 'important' ? 'danger' : 'info'
            "
          />
        </div>
      </template>
      <template #content>
        <div class="text-gray-600 mb-8 min-h-[400px]">
          {{ announce.content }}
        </div>
        <div class="flex justify-between text-sm border-t pt-4">
          <span>作成日: {{ basicFormatter(announce.createdAt) }}</span>
          <span>更新日: {{ basicFormatter(announce.updatedAt) }}</span>
        </div>
        <div v-if="announce.publishedAt" class="mt-4 text-sm">
          公開日: {{ basicFormatter(announce.publishedAt) }}
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, reactive, inject } from "vue";
import { basicFormatter, luxonNow } from "/@/modules/luxon";
import { createDateForCalendars } from "/@/modules/calendar";
import { useRouterUtil, useUser, useAnnounces } from "/@/vue/composables";
import {
  AttendanceForm as AttendanceFormType,
  DateMap,
  OrganizationClient,
} from "/@/types";
import { Ref } from "vue";
import Card from "primevue/card";
import Tag from "primevue/tag";

const { goto, id } = useRouterUtil();

// user

const { getCurrentUser } = useUser();

// organization

const selectedOrganizationId = inject<Ref<number>>("selectedOrganizationId");
const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

// announces

const { getAnnounce } = useAnnounces();
const { data: announce } = getAnnounce(id, selectedOrganizationId, {
  system: true,
});
</script>

<style scoped></style>
