import Index from "./Index.vue";
import Inquiry from "./Inquiry.vue";
import SignIn from "./SignIn.vue";
import SignUp from "./SignUp.vue";
import NotFound from "./NotFound.vue";
import PasswordReset from "./PasswordReset.vue";

import { Router as UserRouter, Root as UserRoot } from "./Users";

export const routes = [
  {
    path: "/",
    name: "Index",
    title: "Top",
    meta: {
      dontNeedLogin: true,
    },
    component: Index,
  },
  {
    path: "/sign_up",
    name: "SignUp",
    title: "新規登録",
    meta: {
      dontNeedLogin: true,
    },
    component: SignUp,
  },
  {
    path: "/sign_in",
    name: "SignIn",
    title: "ログイン",
    meta: {
      dontNeedLogin: true,
    },
    component: SignIn,
  },
  {
    path: "/password_reset",
    name: "PasswordReset",
    title: "パスワードリセット",
    meta: {
      dontNeedLogin: true,
    },
    component: PasswordReset,
  },
  {
    path: "/inquiries",
    name: "InquiriesNew",
    title: "お問い合わせ",
    meta: {
      dontNeedLogin: true,
    },
    component: Inquiry,
  },
  {
    path: "/user",
    title: "ユーザー画面",
    component: UserRoot,
    children: [...UserRouter],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    title: "Not Found",
    meta: {
      dontNeedLogin: true,
    },
    component: NotFound,
  },
];
