import Index from "./Index.vue";

export default [
  {
    path: "",
    name: "UsersNotificationsIndex",
    component: Index,
    meta: {
      title: "通知一覧",
    },
  },
];
