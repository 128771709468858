<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <div class="flex flex-wrap items-center">
        <SelectObject
          v-if="organizations && organizations.length && false"
          v-model="selectedOrganizationId"
          option-label="name"
          option-value="id"
          :options="organizations"
          class="me-4 mb-4"
        />

        <SelectObject
          v-if="organizationMembers && organizationMembers.length"
          v-model="selectedOrganizationMemberId"
          :option-label="
            (member) => member.user?.lastName + member.user?.firstName
          "
          option-value="id"
          :options="organizationMembers"
          class="me-4 mb-4"
        />

        <SelectDate
          v-model:year="selectedDate.year"
          v-model:month="selectedDate.month"
          :existsDates="existsDates"
          class="mb-4"
        />
      </div>

      <BasicButton
        label="出勤簿へ"
        icon="pi pi-book"
        class="mb-4"
        @click="goto({ name: 'UsersAttendanceRecordsIndex' })"
      />
    </div>

    <div
      v-if="attendances && attendances.length"
      class="bg-white dark:bg-gray-800 p-4 rounded-md"
    >
      <DataTable :value="dates" tableStyle="min-width: 50rem" scrollable>
        <Column field="dateKey" header="日にち" style="min-width: 135px">
          <template #body="slotProps">
            <Tag
              :value="basicFormatter(slotProps.data.dateKey, 'noYear')"
              :severity="dateSeverity(slotProps.data)"
            />
          </template>
        </Column>
        <Column field="startAt" header="時刻" style="min-width: 130px">
          <template #body="slotProps">
            <template v-if="eachDateAttendance[slotProps.data.dateKey]">
              <div
                v-for="(dk, idx) in eachDateAttendance[slotProps.data.dateKey]"
                :key="slotProps.data.dateKey + 'startAt'"
                class="flex"
                :class="{ 'mt-4': idx !== 0 }"
              >
                <Tag
                  v-if="dk.attendanceType === 'holiday'"
                  value="欠勤"
                  severity="danger"
                />
                <Tag
                  v-else-if="dk.attendanceType === 'paid_holiday'"
                  value="有休"
                  severity="danger"
                />
                <Tag
                  v-else
                  severity="success"
                  :value="`${basicFormatter(dk.startAt, 'onlyTime')}~${
                    dk.finishAt
                      ? basicFormatter(dk.finishAt, 'onlyTime')
                      : '勤務中'
                  }`"
                  class="me-2"
                />
              </div>
            </template>
          </template>
        </Column>
        <Column header="詳細" style="width: 100%; min-width: 200px">
          <template #body="slotProps">
            <template v-if="eachDateAttendance[slotProps.data.dateKey]">
              <div
                v-for="(dk, idx) in eachDateAttendance[slotProps.data.dateKey]"
                :key="dk.id"
                class="flex justify-between items-center"
                :class="{ 'mt-4': idx !== 0 }"
              >
                <div v-if="dk.attendanceType === 'holiday'">
                  <span class="me-2">
                    {{ absenceTypesLabel(dk.absenceType) }}
                  </span>
                  <span class="me-2"> {{ dk.bodyTemperature }}℃ </span>
                  <span>
                    {{ dk.explanation }}
                  </span>
                </div>
                <div v-else>
                  <span v-if="dk.evaluationType">
                    <Tag
                      :value="evaluationTypeLabel(dk.evaluationType)"
                      class="me-2"
                    />
                  </span>
                  <span v-if="dk.report">
                    {{ dk.report }}
                  </span>
                </div>
                <span v-if="dk.edited">※編集済</span>
              </div>
            </template>
          </template>
        </Column>
        <Column style="min-width: 100px">
          <template #body="{ data }">
            <div
              v-for="(at, idx) in eachDateAttendance[data.dateKey]"
              :key="at"
            >
              <BasicButton
                label="編集"
                icon="pi pi-pencil"
                class="me-2"
                text
                small
                :class="{ 'mt-4': idx !== 0 }"
                @click="openEditModal(at.id)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <Card v-else>
      <template #content> 選択した月のデータがありません </template>
    </Card>

    <SimpleModal v-model:visible="showEditModal" title="変更依頼">
      <AttendanceForm
        v-if="selectedAttendance"
        v-model:evaluation-type="form.evaluationType"
        v-model:report="form.report"
        v-model:start-at="form.correctionStartAt"
        v-model:finish-at="form.correctionFinishAt"
        from-edit-modal
        :current-attendance="selectedAttendance"
        submit-correction-request
        :errors="errors"
        class="mb-4"
      />
      <div class="flex justify-end">
        <BasicButton @click="update"> 変更依頼 </BasicButton>
      </div>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, reactive, inject } from "vue";
import {
  basicFormatter,
  fromISO,
  luxonNow,
  existsDates,
} from "/@/modules/luxon";
import { createDateForCalendars } from "/@/modules/calendar";
import {
  useRouterUtil,
  useUser,
  useOrganizationMember,
  useAttendance,
  useZodScheme,
} from "/@/vue/composables";
import { BasicButton, SelectObject, SelectDate } from "/@/vue/components/Atom";
import { Simple as SimpleModal } from "/@/vue/components/Molecules";
import { Form as AttendanceForm } from "/@/vue/components/Organisms/Attendances";
import {
  absenceTypesLabel,
  AttendanceCheckScheme,
  AttendanceClient,
  AttendanceForm as AttendanceFormType,
  DateMap,
  evaluationTypeLabel,
  OrganizationClient,
} from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import Card from "primevue/card";
import { Ref } from "vue";

const { goto } = useRouterUtil();

// user

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser({
  withTodayShifts: true,
});

// organization

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});

const selectedOrganizationId = inject("selectedOrganizationId");
const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganizationId
);

const selectedOrganizationMemberId = ref<number>();
const selectedOrganizationMember = computed(() => {
  if (!organizationMembers.value) return;
  return organizationMembers.value.find(
    (m) => m.id === selectedOrganizationMemberId.value
  );
});

// date

const today = luxonNow();

const selectedDate = reactive<DateMap>({
  year: today.year,
  month: today.month,
});

const dates = computed(() => {
  return createDateForCalendars(selectedDate);
});

function dateSeverity(date: { weekday: number }) {
  if (date.weekday === 5) {
    return "info";
  } else if (date.weekday === 6) {
    return "danger";
  } else {
    return "secondary";
  }
}

// attendances

const { getAttendances } = useAttendance();
const { data: attendances } = getAttendances(
  selectedOrganizationMemberId,
  selectedDate
);

const eachDateAttendance = computed(() => {
  if (attendances.value && attendances.value.length && dates.value.length) {
    let m = {};

    dates.value.forEach((d) => {
      m[d.dateKey] = attendances.value.filter(
        (a) => basicFormatter(a.startAt, "iso") === d.dateKey
      );
    });

    return m;
  } else {
    return {};
  }
});

// form

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<AttendanceFormType>(
  AttendanceCheckScheme
);

function resetForm(attendance?: AttendanceClient) {
  form.id = attendance?.id || undefined;
  form.evaluationType = attendance?.evaluationType || "excellent";
  form.report = attendance?.report || undefined;

  const baseStart = { hour: 9, minute: 0 };
  const baseFinish = { hour: 18, minute: 0 };

  form.correctionStartAt = attendance?.startAt
    ? fromISO(attendance?.startAt).set(baseStart).toISO()
    : today.set(baseStart).toISO();
  form.correctionFinishAt = attendance?.finishAt
    ? fromISO(attendance?.finishAt).set(baseFinish).toISO()
    : today.set(baseFinish).toISO();
}

// modal

const showEditModal = ref(false);

const selectedAttendanceId = ref<number>();
const selectedAttendance = computed(() => {
  if (!attendances.value) return;
  return attendances.value.find((a) => a.id === selectedAttendanceId.value);
});

function openEditModal(id: number) {
  showEditModal.value = true;

  selectedAttendanceId.value = id;
  resetForm(selectedAttendance.value);
}

function closeEditModal() {
  showEditModal.value = false;
}

const { update: updateAttendance } = useAttendance();

async function update() {
  if (!selectedAttendance.value) return;

  if (await updateAttendance(form)) {
    alert("変更依頼を作成しました");
    closeEditModal();
  } else {
    alert(
      "変更の依頼に失敗しました。すでに変更依頼を出しているか、なんらかな問題が発生しました"
    );
  }
}
</script>

<style scoped></style>
