<template>
  <div>
    <template v-if="startTeleport">
      <teleport to="#users-nav-area">
        <Card style="--p-card-body-padding: 0.25rem" class="">
          <template #content>
            <div class="flex items-center">
              <Button
                icon="pi pi-cog"
                severity="secondary"
                text
                @click="toggleConfigPopup"
              />
              <Popover ref="configPopup">
                <div class="flex flex-col">
                  <label class="mb-4">表示するカレンダー</label>
                  <div
                    v-for="(item, idx) in calendarItems"
                    class="flex items-center mb-4"
                  >
                    <Checkbox
                      v-model="selectedItems"
                      :value="item"
                      class="me-2 rounded"
                      :inputId="`calendar-item-${idx}`"
                    />
                    <label :for="`calendar-item-${idx}`" class="me-4">{{
                      calendarItemLabel(item)
                    }}</label>
                  </div>
                </div>
              </Popover>

              <MenuButton
                label=""
                :menu-items="menuItems"
                icon="pi pi-file-edit"
                severity="secondary"
              />
            </div>
          </template>
        </Card>
      </teleport>
    </template>

    <Card>
      <template #content>
        <CalendarCole
          v-if="jpHolidays"
          :events="events"
          v-model:selected-date="selectedDate"
          :jp-holidays="jpHolidays"
          class="h-full min-h-max"
          :is-mobile="isMobile"
          @eventClick="openEventModal"
        />
      </template>
    </Card>

    <SimpleModal
      v-model:visible="holidayAppModalVisible"
      size="small"
      title="希望休申請"
      @afterHide="closeHolidayAppModal"
    >
      <div class="">
        <SelectObject
          v-if="organizations && organizations.length"
          v-model="selectedOrganizationId"
          option-label="name"
          option-value="id"
          :options="organizations"
          style="min-width: 150px"
          class="mb-4"
        />
        <div class="flex flex-col items-center justify-center mb-4">
          <DateForm
            v-model="form.date"
            multiple
            inline
            :min-date="today"
            style="max-width: 400px"
          />
        </div>
        <div class="flex align-center justify-end">
          <BasicButton
            variant="secondary"
            icon="pi pi-times"
            label="キャンセル"
            class="mr-4"
            text
            @click="closeHolidayAppModal"
          />
          <BasicButton
            variant="primary"
            label="申請"
            @click="submitHolidayApp"
          />
        </div>
      </div>
    </SimpleModal>

    <SimpleModal v-model:visible="eventModalVisible" title="詳細" size="small">
      <template v-if="selectedEvent && selectedProps">
        <div class="flex-col">
          <p class="mb-4">
            <Tag value="タイトル" class="me-4" />
            <span>{{ selectedEvent.title }}</span>
          </p>
          <p class="mb-4">
            <Tag value="日時" class="me-4" />
            <span>{{
              selectedProps.startAt && selectedProps.finishAt
                ? `${basicFormatter(
                    selectedProps.startAt,
                    "withTime"
                  )}~${basicFormatter(selectedProps.finishAt, "onlyTime")}`
                : basicFormatter(selectedProps.date)
            }}</span>
          </p>
        </div>
      </template>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  reactive,
  ref,
  onMounted,
  inject,
  onUpdated,
  watch,
} from "vue";
import type { EventClickArg, EventApi } from "@fullcalendar/core";
import { basicFormatter, luxonNow } from "/@/modules/luxon";
import { createCalendarEvent } from "/@/modules/calendar";
import {
  useCalendar,
  useUser,
  useMemberApplications,
  useMqUtils,
} from "/@/vue/composables";
import { Core as CalendarCole } from "/@/vue/components/Organisms/Calendar";
import {
  DateForm,
  Label as FormLabel,
  SelectObject,
} from "/@/vue/components/Atom";
import { MenuButton } from "/@/vue/components/Molecules/Button";
import { Simple as SimpleModal } from "/@/vue/components/Molecules";
import {
  DateMap,
  MemberApplicationStatus,
  MemberApplicationType,
  OrganizationClient,
  shiftTypeLabel,
} from "/@/types";
import BasicButton from "/@/vue/components/Atom/Buttons/BasicButton.vue";

import Card from "primevue/card";
import SelectButton from "primevue/selectbutton";
import type { MenuItem } from "primevue/menuitem";
import Tag from "primevue/tag";
import Checkbox from "primevue/checkbox";
import Popover from "primevue/popover";
import Button from "primevue/button";

// mq

const { isMobile } = useMqUtils();

//

const startTeleport = ref(false);

onMounted(() => {
  startTeleport.value = true;
});

const configPopup = ref();

function toggleConfigPopup(e: any) {
  configPopup.value.toggle(e);
}

import { memberTypeLabel } from "/@/types";
import { Ref } from "vue";

const { getJpHolidays } = useCalendar();
const { data: jpHolidays } = getJpHolidays();

const today = luxonNow();

const selectedDate = ref<DateMap>({
  year: today.year,
  month: today.month,
});

// user

const { getCurrentUser, getCalendar } = useUser();
const { data: currentUser } = getCurrentUser({});
const { data: calendarInfo, mutate: getCalendarMutate } =
  getCalendar(selectedDate);

// organizations

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});
const organizationMemberIds = computed(() => {
  if (!organizations.value) return [];
  return organizations.value.map((o) => o.organizationMemberId);
});

const selectedOrganizationId = ref<number>();
const selectedOrganization = inject<Ref<OrganizationClient>>(
  "selectedOrganization"
);

const hasManagedOrganization = computed(() => {
  return organizations.value && organizations.value.some((o) => o.isManager);
});

// event

type CalendarItem =
  | "shift"
  | "memberApplication"
  | "managedMemberShift"
  | "managedMemberApplication";
const calendarItems = computed<CalendarItem[]>(() => {
  if (hasManagedOrganization.value) {
    return [
      "shift",
      "memberApplication",
      "managedMemberShift",
      "managedMemberApplication",
    ];
  } else {
    return ["shift", "memberApplication"];
  }
});

const selectedItems = ref<CalendarItem[]>(["shift", "memberApplication"]);

function calendarItemLabel(item: CalendarItem) {
  switch (item) {
    case "shift":
      return "シフト";
    case "managedMemberShift":
      return "メンバーのシフト";
    case "memberApplication":
      return "申請";
    case "managedMemberApplication":
      return "メンバーの申請";
  }
}

const primaryColor = "#f97316";
const subPrimaryColor = "#f9a03f";
const dangerColor = "#d87171";
const successColor = "#97a399";
const warningColor = "#f59e0b";

const events = computed(() => {
  if (!calendarInfo.value) return [];

  const shiftEvnts = calendarInfo.value.shifts.map((shift) => {
    return createCalendarEvent({
      id: `shift-${shift.id}`,
      title: shiftTypeLabel(shift.shiftType),
      start: shift.startAt,
      end: shift.finishAt,
      allDay:
        shift.shiftType === "holiday" || shift.shiftType === "paid_holiday",
      color: shift.isHoliday ? dangerColor : primaryColor,
      extendedProps: {
        propsType: "Shift",
        ...shift,
      },
    });
  });

  let managedMemberShiftsEvents = calendarInfo.value.managedMemberShifts
    .filter((s) => {
      return !organizationMemberIds.value.includes(s.organizationMemberId);
    })
    .map((shift) => {
      return createCalendarEvent({
        id: `member-shift-${shift.id}`,
        title: `${shift.userName} ${shiftTypeLabel(shift.shiftType)}`,
        start: shift.startAt,
        end: shift.finishAt,
        allDay:
          shift.shiftType === "holiday" || shift.shiftType === "paid_holiday",
        color: shift.isHoliday ? dangerColor : subPrimaryColor,
        extendedProps: {
          propsType: "Shift",
          ...shift,
        },
      });
    });

  const applicationEvents = calendarInfo.value.memberApplications
    .filter(
      (ma) =>
        !(
          ma.applicationType === "paid" &&
          ma.memberApplicationStatus === "approved"
        )
    )
    .map((app) => {
      return createCalendarEvent({
        id: `application-${app.id}`,
        title:
          memberTypeLabel(app.applicationType) +
          memberApplicationStatusToCalendarStatus(app.memberApplicationStatus),
        start: app.date,
        allDay: true,
        color: app.applicationType === "paid" ? dangerColor : successColor,
        extendedProps: {
          propsType: "MemberApplication",
          ...app,
        },
      });
    });

  const managedApplicationEvents = calendarInfo.value.managedMemberApplications
    .filter((app) => {
      return !organizationMemberIds.value.includes(app.organizationMemberId);
    })
    .map((app) => {
      return createCalendarEvent({
        id: `member-application-${app.id}`,
        title:
          `${app.userName} ${memberTypeLabel(app.applicationType)}` +
          memberApplicationStatusToCalendarStatus(app.memberApplicationStatus),
        start: app.date,
        allDay: true,
        color: app.applicationType === "paid" ? dangerColor : successColor,
        extendedProps: {
          propsType: "MemberApplication",
          ...app,
        },
      });
    });

  let results = [];

  if (selectedItems.value.includes("shift")) {
    results = [...results, ...shiftEvnts];
  }

  if (selectedItems.value.includes("managedMemberShift")) {
    results = [...results, ...managedMemberShiftsEvents];
  }

  if (selectedItems.value.includes("memberApplication")) {
    results = [...results, ...applicationEvents];
  }

  if (selectedItems.value.includes("managedMemberApplication")) {
    results = [...results, ...managedApplicationEvents];
  }

  return results;
});

// application modal

const selectedMemberApplicationType = ref<MemberApplicationType>("paid");

const menuItems = computed<MenuItem[]>(() => {
  return [
    {
      label: "希望休申請",
      icon: "pi pi-calendar",
      command: () => {
        selectedMemberApplicationType.value = "holiday";
        openHolidayAppModal();
      },
    },
    {
      label: "有休申請",
      icon: "pi pi-calendar",
      command: () => {
        selectedMemberApplicationType.value = "paid";
        openHolidayAppModal();
      },
    },
  ];
});

//

function memberApplicationStatusToCalendarStatus(
  status: MemberApplicationStatus
) {
  switch (status) {
    case "pending":
      return "(承認待ち)";
    case "approved":
      return "";
    case "rejected":
      return "(却下)";
  }
}

// holiday modal

const form = reactive({
  date: [],
});

const holidayAppModalVisible = ref(false);

function openHolidayAppModal() {
  holidayAppModalVisible.value = true;
}

function closeHolidayAppModal() {
  form.date = [];
  holidayAppModalVisible.value = false;
}

const { bulkCreateMemberApplications } = useMemberApplications();

async function submitHolidayApp() {
  const organization = selectedOrganization.value;

  if (!organization) return;

  if (
    await bulkCreateMemberApplications(
      organization.organizationMemberId,
      form.date,
      selectedMemberApplicationType.value
    )
  ) {
    form.date = [];
    getCalendarMutate();
    closeHolidayAppModal();
  } else {
    console.error("Failed to submit holiday application");
  }
}

// event modal

const selectedEvent = ref();
const selectedProps = computed(() => {
  if (!selectedEvent.value) return;
  return selectedEvent.value.extendedProps;
});

const eventModalVisible = ref(false);

function openEventModal(event: EventApi, eventClickInfo: EventClickArg) {
  eventModalVisible.value = true;
  selectedEvent.value = event;
}

function closeEventModal() {
  eventModalVisible.value = false;
}
</script>

<style scoped></style>
