import New from "./New.vue";

export default [
  {
    path: "new",
    name: "UsersInquiriesNew",
    component: New,
    meta: {
      title: "お問い合わせ",
    },
  },
];
