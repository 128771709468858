<template>
  <div>
    <div class="calendar-wrapper">
      <FullCalendar ref="fullcalendar" :options="options" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import jaLocale from "@fullcalendar/core/locales/ja";

import interactionPlugin from "@fullcalendar/interaction";
import type {
  CalendarOptions,
  DayCellContentArg,
  DayCellMountArg,
  EventClickArg,
  DateSelectArg,
  EventApi,
} from "@fullcalendar/core";
import type { DateMap, JpHolidays } from "/@/types";
import { fromISO } from "/@/modules/luxon";

const props = defineProps<{
  events: Partial<EventApi>[];
  selectedDate?: DateMap;
  existsDates?: string[];
  isMobile?: boolean;
  jpHolidays?: JpHolidays;
}>();

const emits = defineEmits<{
  (e: "dateClick", starStr: string, dateClickInfo: DateSelectArg): void;
  (e: "eventClick", event: EventApi, eventClickInfo: EventClickArg): void;
  (e: "update:selectedDate", selectedDate: DateMap): void;
}>();

const validRange = computed(() => {
  if (!props.existsDates) {
    return;
  }
  const startMonth = props.existsDates.at(0);
  const endMonth = props.existsDates.at(-1);

  if (!startMonth || !endMonth) {
    return;
  }

  return {
    start: fromISO(startMonth).minus({ month: 1 }).startOf("month").toJSDate(),
    end: fromISO(endMonth).plus({ month: 1 }).endOf("month").toJSDate(),
  };
});

function createCalendarOptions(options = {}) {
  return {
    plugins: [dayGridPlugin, interactionPlugin, listPlugin],
    headerToolbar: {
      start: "prev,next,today",
      center: "title",
      end: "",
    },
    businessHours: true,
    navLinks: false,
    initialView: props.isMobile ? "listMonth" : "dayGridMonth",
    locales: [jaLocale],
    locale: "ja",
    themeSystem: "standard",
    height: "auto",
    validRange: validRange.value,
    dayCellContent: (e: DayCellContentArg) => {
      return e.dayNumberText.replace("日", "");
    },
    dayCellDidMount: (e: DayCellMountArg) => {
      if (!props.jpHolidays) {
        return;
      }

      if (props.jpHolidays[e.date.toLocaleDateString("sv-SE")]) {
        // class を追加
        (e.el as HTMLElement).classList.add("fc-day-holiday");
      }
    },
    dateClick: (info: DateSelectArg) => {
      emits("dateClick", info.startStr, info);
    },
    eventClick(info: EventClickArg) {
      emits("eventClick", info.event, info);
    },
    //datesSet: updateCurrentYearAndMonth,
    ...options,
  };
}

const options = computed<CalendarOptions>(() => {
  return createCalendarOptions({
    events: props.events,
    height: "100vh",
  });
});

// current selected date info

const fullcalendar = ref();

function getCurrentCalendarDate() {
  const calendarApi = fullcalendar.value.getApi();
  const currentDate = calendarApi.getDate();

  return {
    year: currentDate.getFullYear() as number,
    month: (currentDate.getMonth() + 1) as number,
  };
}

function updateCurrentYearAndMonth() {
  const newDateMap = getCurrentCalendarDate();

  emits("update:selectedDate", newDateMap);
}

function gotoSelectedYearAndMonth() {
  if (!props.selectedDate) {
    return;
  }

  const calendarApi = fullcalendar.value.getApi();
  const date = new Date(
    props.selectedDate.year,
    props.selectedDate.month - 1,
    1
  );
  calendarApi.gotoDate(date);
}

watch(
  () => props.selectedDate,
  () => {
    const current = getCurrentCalendarDate();
    if (
      current.year !== props.selectedDate?.year ||
      current.month !== props.selectedDate?.month
    ) {
      gotoSelectedYearAndMonth();
    }
  }
);

onMounted(() => {
  if (!props.selectedDate) {
    updateCurrentYearAndMonth();
  } else {
    gotoSelectedYearAndMonth();
  }
  fullcalendar.value.getApi().on("datesSet", updateCurrentYearAndMonth);
});
</script>

<style lang="scss">
.calendar-wrapper {
  min-height: 100%;
}

.fc {
  --fc-non-business-color: var(--p-bg-color);
  font-size: 12px;

  .fc-button-group {
    --fc-button-bg-color: #fff;
    --fc-button-border-color: #fff;
    --fc-button-text-color: #000;
    --fc-button-hover-bg-color: #f0f0f0;
    --fc-button-hover-border-color: #f0f0f0;
    --fc-button-active-bg-color: #f0f0f0;
    --fc-button-active-border-color: #f0f0f0;
  }

  .fc-day {
    &.fc-day-sun,
    &.fc-day-holiday {
      .fc-daygrid-day-top,
      .fc-scrollgrid-sync-inner {
        .fc-col-header-cell-cushion,
        .fc-daygrid-day-number {
          color: #ea4335;
        }
      }
    }

    &.fc-day-sat {
      .fc-daygrid-day-top,
      .fc-scrollgrid-sync-inner {
        .fc-col-header-cell-cushion,
        .fc-daygrid-day-number {
          color: #0080ff;
        }
      }
    }

    &.fc-day-other {
      .fc-daygrid-day-top {
        opacity: 1;

        a {
          opacity: 0.3;
        }
      }
    }
  }

  .fc-col-header-cell {
    background-color: var(--p-content-background);
  }

  .fc-daygrid-day-top {
    flex-direction: row;
    justify-content: center;
  }
}

.fc-theme-standard th {
  border-bottom: none;
}
</style>
