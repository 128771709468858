// import Index from "./Index.vue";
import Show from "./Show.vue";

export default [
  {
    path: ":id",
    name: "UsersSystemAnnouncesShow",
    component: Show,
    meta: {
      title: "システムアナウンス詳細",
    },
  },
];
